.teammember{
	/* max-width: 600px; */
	min-height: 220px;
	background-color: #fafafa;
	margin: 0 0 20px 0;
	padding: 20px !important;
}

.headshot{
	float: left;
	padding: 0 20px 0 0;
}
   
.headshot img{
	max-width: 143px;
}

.teammemberinfo{
	padding: 0 10px 0 20px;
}

.teammemberinfo h3{
	font-size: 20px !important;
}

a.requestService{
	text-transform: uppercase;
	font-weight: bold;
	font-size: 25px;
	border-radius: 3px;
}

.requestServices a:hover{
	color: white !important;
}
       
@media screen and (max-width: 400px){

	.headshot{
		float: none;
		margin: 0 auto;
		display: block;
		padding: 0px !important;	 
	}
			
	.teammemberinfo{
		text-align: center;
		padding: 0 0 0 0 !important;
	}
			
}

section.serviceSidebar > a.requestService{
	line-height: 35px !important;
	text-align: center !important;
}

a.requestService::before{
	display: none;
}

.projectList a:hover{
	cursor: default !important;
}

@media (min-width: 64em){

	.serviceBanner {
		padding: 6em 0 3em 0 !important; 
	}
	
}